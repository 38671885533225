<template>
    <div class="preview_area show_sidebar workout_config" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <div class="section_container">
                    <Form @invalid-submit="handleFormInvalidSubmit" @submit="handleSubmitForm" v-slot="{ errors }" ref="edit-block-exercise-form">
                        <h2 class="section_title">{{ form.name }}</h2>
                        <div class="content_panel" :class="selectedWorkoutBlock.perform_type == 2 ? 'circuit' : 'progression', {'no_superset' : selectedWorkoutBlock.exercises.length == 1}">
                            <div class="edit_content">
                                <div class="thumb_section">
                                    <div class="video_section" :class="{ video_section_flip: form.is_flip == 1 }">
                                        <img :src="selectedExercise.thumb" :alt="selectedExercise.name">
                                    </div>
                                    <div class="thumb_info">
                                        <div class="flip_image">
                                            <div class="capsule_elm">
                                                <h5>Flip</h5>
                                                <label class="switch_option capsule_btn">
                                                    <input type="checkbox" id="flip" :true-value="1" :false-value="0" v-model="form.is_flip" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                        </div>
                                        <h4 class="thumb_title">{{ form.name }}
                                            <span class="v_tag" v-if="form.is_variable">V</span>
                                        </h4>
                                        <h6 class="exercise_info">
                                            {{ form.measure_type != 2 ? `${form.sets} x` : '' }} <span v-if="form.measure_type != 2">{{ form.is_reps_ranges ? `${form.min_reps}-${form.max_reps}` : form.exercise_reps }} reps</span>
                                            <span v-if="form.measure_type == 2"><a v-if="form.exercise_minutes">{{ form.exercise_minutes }} min</a> <a v-if="form.exercise_seconds">{{ form.exercise_seconds }} sec</a></span> |
                                            <span v-if="form.is_rest"><a v-if="form.rest_minutes">{{ form.rest_minutes }} min</a> <a v-if="form.rest_seconds">{{ form.rest_seconds }} sec rest</a></span>
                                            <span v-else>No rest</span>
                                        </h6>
                                    </div>
                                </div>
                                <div class="setting_wpr">
                                    <div class="form_grp p-0 mb-4">
                                        <div class="group_item">
                                            <label class="input_label">Measure</label>
                                            <div class="field_wpr">
                                                <multiselect v-model="form.measure_type" v-bind="measureUnits"></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp p-0">
                                        <div class="group_item">
                                            <div class="capsule_elm mb-1">
                                                <label class="input_label">Tempo</label>
                                                <label class="switch_option capsule_btn p-0">
                                                    <input type="checkbox" id="display_header" :true-value="1" :false-value="0" v-model="form.is_tempo" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="field_wpr" v-if="form.is_tempo">
                                                <input type="text" v-model="form.tempo">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="setting_wpr">
                                <div class="form_grp set_wpr">
                                    <div class="public_name">
                                        <label class="input_label">Public Name</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                            <Field autocomplete="off" type="text" name="name" v-model="form.name" rules="required" />
                                        </div>
                                        <ErrorMessage name="name" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp set_wpr">
                                    <div class="public_descriptions">
                                        <label class="input_label">Exercise Description </label>
                                        <div class="field_wpr">
                                            <textarea @input="autoGrowTextarea" class="auto-grow" name="description" cols="30" rows="10" v-model="form.description" placeholder="Description goes here.."></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp set_wpr">
                                    <div class="single_item sets" :class="{ disable: form.measure_type == 3, sets: form.measure_type == 3 }">
                                        <label class="input_label">Sets</label>
                                        <label class="fld_label">Number</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.sets }">
                                            <Field autocomplete="off" v-model="form.sets" type="number" :onWheel="(ev) => ev.currentTarget.blur()" name="sets" min="1" @keyup="enforceMinMax" @change="handleExerciseSet()" rules="required|min_value:1" />
                                        </div>
                                        <ErrorMessage name="sets" class="text-danger" />
                                        <div class="capsule_elm mt-2" v-if="form.measure_type == 1">
                                            <h5>Variable</h5>
                                            <label class="switch_option capsule_btn" for="variable">
                                                <input type="checkbox" id="variable" :true-value="1" :false-value="0" v-model="form.is_variable" @change="handleToggleExerciseSet" :disabled="form.measure_type == 2" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="single_item" v-if="form.measure_type !== 2">
                                        <label class="input_label">Reps</label>
                                        <div class="column-2 mb-2" v-if="form.is_reps_ranges">
                                            <div class="item">
                                                <label class="fld_label">Min</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.rep_range_min }">
                                                    <Field autocomplete="off" type="number" name="rep_range_min" min="1" @keyup="enforceMinMax" v-model="form.min_reps" rules="required|min_value:1" label="reps" />
                                                </div>
                                                <ErrorMessage name="rep_range_min" class="text-danger" />
                                            </div>
                                            <div class="item">
                                                <label class="fld_label">Max</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.rep_range_max }">
                                                    <Field autocomplete="off" type="number" name="rep_range_max" min="1" @keyup="enforceMinMax" v-model="form.max_reps" rules="required|min_value:1" label="reps" />
                                                </div>
                                                <ErrorMessage name="rep_range_max" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="mb-2" v-else>
                                            <label class="fld_label">Number</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.exercise_reps }" v-if="!form.is_failure">
                                                <Field autocomplete="off" type="number" name="exercise_reps" min="1" @keyup="enforceMinMax" v-model="form.exercise_reps" rules="required|min_value:1" label="reps" />
                                            </div>
                                            <ErrorMessage name="exercise_reps" class="text-danger" v-if="form.is_failure" />
                                            <div class="field_wpr" v-if="form.is_failure">
                                                <input type="text" placeholder="Max" disabled>
                                            </div>
                                        </div>
                                        <div class="capsule_elm" v-if="form.measure_type !== 2">
                                            <h5>Use Rep Ranges</h5>
                                            <label class="switch_option capsule_btn p-0" :for="`is_rep_range-65654`" v-if="form.measure_type !== 2">
                                                <input type="checkbox" :id="`is_rep_range-65654`" :true-value="1" :false-value="0" v-model="form.is_reps_ranges" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="capsule_elm" v-if="form.measure_type !== 2 && !form.is_reps_ranges">
                                            <h5>To Failure</h5>
                                            <label class="switch_option capsule_btn" for="failure">
                                                <input type="checkbox" id="failure" :true-value="1" :false-value="0" v-model="form.is_failure" @change="handleFailure" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="single_item" v-if="form.measure_type == 2">
                                        <label class="input_label">Time</label>
                                        <div class="column-2">
                                            <div class="item">
                                                <label class="fld_label">Minutes</label>
                                                <div class="field_wpr">
                                                    <input type="number" min="0" @keyup="enforceMinMax" v-model="form.exercise_minutes">
                                                </div>
                                            </div>
                                            <div class="item">
                                                <label class="fld_label">Seconds</label>
                                                <div class="field_wpr">
                                                    <input type="number" min="0" max="60" @keyup="enforceMinMax" v-model="form.exercise_seconds">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="single_item load">
                                        <label class="input_label">Load</label>
                                        <div class="column-2 mb-2">
                                            <div class="item" v-if="form.load_unit !== 'Bodyweight' && !form.is_bodyweight && form.load_unit !== 'Light' && form.load_unit !== 'Medium' && form.load_unit !== 'Heavy' && form.load_unit !== 'Easy' && form.load_unit !== 'Moderate' && form.load_unit !== 'Hard'">
                                                <label class="fld_label">Number</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.load_number }">
                                                    <Field autocomplete="off" type="number" name="load_number" min="1" @keyup="enforceMinMax" v-model="form.load_number" rules="required|min_value:1" label="load" />
                                                </div>
                                                <ErrorMessage name="load_number" class="text-danger" />
                                            </div>
                                            <div class="item">
                                                <label class="fld_label">Unit</label>
                                                <div class="field_wpr">
                                                    <multiselect v-model="form.load_unit" v-bind="loadUnits" :disabled="form.is_bodyweight ? true : false"></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="capsule_elm">
                                            <h5>Bodyweight Only</h5>
                                            <label class="switch_option capsule_btn" for="bodyweight">
                                                <input type="checkbox" id="bodyweight" :true-value="1" :false-value="0" v-model="form.is_bodyweight" @change="handleSubmitForm" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="single_item rest">
                                        <div class="capsule_elm mb-2">
                                            <label class="input_label m-0">Rest</label>
                                            <label class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="display_header" :true-value="1" :false-value="0" v-model="form.is_rest" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="column-2" v-if="form.is_rest">
                                            <div class="item">
                                                <label class="fld_label">Minutes</label>
                                                <div class="field_wpr">
                                                    <input type="number" min="0" @keyup="enforceMinMax" v-model="form.rest_minutes">
                                                </div>
                                            </div>
                                            <div class="item">
                                                <label class="fld_label">Seconds</label>
                                                <div class="field_wpr">
                                                    <input type="number" min="0" max="60" @keyup="enforceMinMax" v-model="form.rest_seconds">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="superset" v-else><i class="fas fa-infinity"></i>{{ selectedWorkoutBlock.perform_type == 1 ? 'Super Set' : 'No Rest'}}</div>
                                    </div>
                                </div>
                                <div class="var_table" v-if="form.is_variable">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Set</th>
                                                <th v-if="form.measure_type !== 2">Reps</th>
                                                <th v-if="form.measure_type == 2">Time</th>
                                                <th v-if="form.is_tempo">Tempo</th>
                                                <th>Load</th>
                                                <th>Rest</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(set, s) in setSettings" :key="s">
                                                <td>{{s + 1}}</td>
                                                <td v-if="form.measure_type !== 2">
                                                    <div class="row">
                                                        <template v-if="form.is_reps_ranges">
                                                            <div>
                                                                <label class="fld_label">Min</label>
                                                                <input type="number" v-model="set.min_reps">
                                                            </div>
                                                            <div>
                                                                <label class="fld_label">Max</label>
                                                                <input type="number" v-model="set.max_reps">
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <Field :name="`rep_unit-${s}`" autocomplete="off" v-model="set.reps" type="number" :onWheel="(ev) => ev.currentTarget.blur()" min="1" @keyup="enforceMinMax" rules="required|min_value:1" v-if="set.rep_unit !== 'Max Reps'" label="reps" />
                                                            <input type="number" placeholder="Max" disabled v-else>
                                                        </template>
                                                        <div class="form_grp small p-0">
                                                            <div class="field_wpr">
                                                                <multiselect v-model="set.rep_unit" v-bind="repUnits"></multiselect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage :name="`rep_unit-${s}`" class="text-danger" v-if="set.rep_unit !== 'Max Reps'" />
                                                </td>
                                                <td v-if="form.is_tempo">
                                                    <input type="number" v-model="set.tempo">
                                                </td>
                                                <td>
                                                    <div class="row">
                                                        <input type="number" v-model="set.load_number" v-if="form.load_unit !== 'Bodyweight'">
                                                        <div class="form_grp small p-0">
                                                            <div class="field_wpr">
                                                                <multiselect v-model="form.load_unit" v-bind="loadUnits" disabled></multiselect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row">
                                                        <div>
                                                            <label class="fld_label">Min</label>
                                                            <input type="number" v-model="set.rest_minutes">
                                                        </div>
                                                        <div>
                                                            <label class="fld_label">Sec</label>
                                                            <input type="number" v-model="set.rest_seconds">
                                                        </div>
                                                    </div>
                                                </td>
                                                <td v-if="set.is_disable">
                                                    <button type="button" class="pointer" @click="handleRemoveSetSetting(s)"><i class="fas fa-trash danger"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="other_content">
                                    <div class="group_title">
                                        <h4>Attributes<span>(Client does not see this)</span></h4>
                                        <div class="attribute_list py-1" v-if="selectedAttibutes.length" @click="selectedAttibutes = [];">Clear Attributes</div>
                                        <div class="attribute_list py-1" @click="addAttributes = !addAttributes;">{{addAttributes ? 'Close' : 'Add'}} Attributes</div>
                                    </div>
                                    <ul class="att_tags" v-if="selectedFilterTags.length">
                                        <template v-for="(attribute, a) in selectedFilterTags" :key="a">
                                            <li :class="attribute.type">
                                                {{ attribute.name }}
                                                <span class="delete_btn" @click="handleSelectExerciseAttribute(attribute.id)"><i class="fas fa-times"></i></span>
                                            </li>
                                        </template>
                                    </ul>
                                    <div class="empty_box" v-else>
                                        <img src="@/assets/images/empty_state.svg" alt="Empty">
                                        <h4>No attributes found</h4>
                                    </div>
                                    <div class="form_grp mt-4">
                                        <div class="group_item">
                                            <div class="group_title">
                                                <h4>Instructions</h4>
                                            </div>
                                            <div class="field_wpr">
                                                <textarea class="auto-grow" @input="autoGrowTextarea" name="instructions" v-model="form.instructions" cols="30" rows="10"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="action_wpr">
                            <div class="switch_btn_wpr">
                                <div class="capsule_elm mr-3" :class="{'disable' : selectedWorkoutBlock.perform_type == 2}">
                                    <h5>Save As Default</h5>
                                    <label for="save_default" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="save_default" v-model="form.is_default" :true-value="1" :false-value="0" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="capsule_elm mr-3">
                                    <h5>Track Exercise</h5>
                                    <label for="track_exercise" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="track_exercise" v-model="form.is_track_exercise" :true-value="1" :false-value="0" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                            <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="loader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="loader"></i>{{ loader ? ' Updating' : 'Update' }}</button>
                        </div>
                    </Form>
                </div>
            </div>
            <workout-attribute v-model="addAttributes" title="Attribute List" :selected-attibutes="selectedAttibutes" :is-add-attribute="true" :toggle-attribute-select="handleSelectExerciseAttribute" />
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { defineAsyncComponent } from 'vue'

    const WorkoutAttribute = defineAsyncComponent(() => import('@/pages/workout/components/WorkoutAttribute'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import VideoParser from '@/utils/VideoParser'

    export default {
        name: 'Add Smart Block',

        data () {
            return {
                measureUnits: {
                    mode: 'single',
                    value: 1,
                    options: [
                        { value: 1, label: 'Using Reps' },
                        { value: 2, label: 'Using Time' },
                        // { value: 3, label: 'Superset', disabled: true }
                    ]
                },
                loadUnits: {
                    mode: 'single',
                    value: 'LBS',
                    options: [
                        { label: 'Pounds', value: 'Pounds' },
                        { label: 'Kilograms', value: 'Kilograms' },
                        { label: '% of 1RM', value: '% of 1RM' },
                        { label: 'Bodyweight', value: 'Bodyweight' },
                        { label: 'Inches', value: 'Inches' },
                        { label: 'Centimeters', value: 'Centimeters' },
                        { label: 'Feet', value: 'Feet' },
                        { label: 'Meters', value: 'Meters' },
                        { label: 'Yards', value: 'Yards' },
                        { label: 'Miles', value: 'Miles' },
                        { label: 'Kilometers', value: 'Kilometers' },
                        { label: 'Metres', value: 'Metres' },
                        { label: 'Light', value: 'Light' },
                        { label: 'Medium', value: 'Medium' },
                        { label: 'Heavy', value: 'Heavy' },
                        { label: 'Easy', value: 'Easy' },
                        { label: 'Moderate', value: 'Moderate' },
                        { label: 'Hard', value: 'Hard' },
                        // { label: 'Slow', value: 'Slow' },
                        // { label: 'Fast', value: 'Fast' },
                    ]
                },
                repUnits: {
                    mode: 'single',
                    value: 'Reps',
                    options: [
                        { label: 'Reps', value: 'Reps' },
                        { label: 'Max Reps', value: 'Max Reps' },
                    ]
                },
                form: {},
                addAttributes: false,
                selectedAttibutes: [],
                setSettings: [],
                selectedFilterTags: [],
            }
        },

        props: {
            modelValue: Boolean,
            selectedExercise: Object,
            selectedWorkoutBlock: Object,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.resetForm();

                    if (!Object.keys(vm.attributeTypes).length)  {
                        vm.getWorkoutAttributeTypes();
                    }
                } else {
                    vm.selectedAttibutes  = [];
                    vm.selectedFilterTags = [];
                }
            },

            'form.is_bodyweight' (val) {
                const vm = this;

                if (val) {
                    vm.form.load_unit = 'Bodyweight';
                }
            },

            'form.measure_type' (val) {
                const vm = this;

                if (val == 1) {
                    vm.form.sets = vm.form.sets ? vm.form.sets : 1;
                }
            },

            selectedFilterTags: {
                handler (exerciseAttributes) {
                    const vm = this;

                    if (exerciseAttributes && exerciseAttributes.length) {
                        vm.selectedAttibutes = exerciseAttributes.map((attribute) => attribute.id);
                    } else {
                        vm.selectedAttibutes = [];
                    }
                },
                deep: true,
            },
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            WorkoutAttribute
        },

        computed: mapState({
            attributeTypes: state => state.workoutModule.workoutAttributeTypes,
            exerciseAttributes: state => state.workoutModule.exerciseAttributes,
            loader: state => state.workoutModule.workoutBlockExerciseLoader,
        }),

        methods: {
            ...mapActions({
                getWorkoutAttributeTypes: 'workoutModule/getWorkoutAttributeTypes',
                updateBlockExercise: 'workoutModule/updateBlockExercise',
                getWorkoutBlocks: 'workoutModule/getWorkoutBlocks',
                deleteWorkoutAttribute: 'workoutModule/deleteWorkoutAttribute',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSelectExerciseAttribute (attributeId) {
                const vm        = this;
                const attribute = vm.exerciseAttributes.find(({id}) => id == attributeId);

                if (attribute) {
                    vm.handleSelectFilterTag (attribute);
                }
            },

            handleSelectFilterTag (attribute) {
                const vm = this;

                const hasFilter = vm.selectedFilterTags.findIndex((att) => att.id === attribute.id);

                if (hasFilter == -1) {
                    vm.selectedFilterTags.push(attribute);
                } else {
                    vm.selectedFilterTags.splice(hasFilter, 1);
                }
            },

            getAttributeFilterType (id) {
                const vm = this;
                let type = '';

                if (id == 1) {
                    type = 'exercise'
                } else if (id == 2) {
                    type = 'part'
                } else if (id == 3 || id == 4) {
                    type = 'muscle'
                } else if (id == 5) {
                    type = 'equipment'
                } else if (id == 6) {
                    type = 'skeletal'
                } else if (id == 7) {
                    type = 'movement'
                } else if (id == 8) {
                    type = 'position'
                } else if (id == 9) {
                    type = 'contraction'
                } else if (id == 10) {
                    type = 'difficulty'
                } else if (id == 11) {
                    type = 'avatar'
                }

                return type;
            },

            resetForm () {
                const vm = this;

                vm.addAttributes      = false;
                vm.form               = JSON.parse(JSON.stringify(vm.selectedExercise));
                vm.setSettings        = vm.form.set_settings ? vm.form.set_settings : [];
                vm.selectedAttibutes  = vm.form.attributes ? JSON.parse(JSON.stringify(vm.form.attributes)) : [];
                vm.selectedFilterTags = [];

                if (vm.selectedAttibutes && vm.selectedAttibutes.length) {
                    vm.selectedAttibutes.forEach((attributeId) => {
                        const attribute = vm.exerciseAttributes.find(({id}) => id == attributeId);

                        if (attribute) {
                            vm.selectedFilterTags.push(attribute);
                        }
                    });
                }

                vm.handleExerciseSet(false);

                setTimeout(() => {
                    document.getElementsByClassName('auto-grow').forEach((element) => {
                        element.style.height = "5px";
                        element.style.height = (element.scrollHeight) + "px";
                    });
                }, 100);
            },

            handleSubmitForm () {
                const vm = this;

                setTimeout(() => {
                    const exerciseForm = vm.$refs['edit-block-exercise-form'];

                    if (exerciseForm) {
                        exerciseForm.validate().then((result) => {
                            if (result.valid) {
                                vm.form.attributes    = vm.selectedAttibutes;
                                vm.form.set_settings  = vm.setSettings;
                                vm.form.setFieldError = exerciseForm.setFieldError;

                                vm.updateBlockExercise(vm.form);
                            }
                        });
                    }
                }, 100);
            },

            handleExerciseSet (isSubmit = true) {
                const vm = this;

                if (vm.form.is_variable && vm.form.sets > 0) {
                    const totalSets = vm.setSettings && vm.setSettings.length ? vm.setSettings.length : 0;
                    const setCount  = vm.form.sets - totalSets;

                    vm.setSettings.forEach(setting => setting.is_disable = 0);

                    if (setCount != 0) {
                        if (setCount > 0) {
                            const setting = {
                                                reps: vm.form.exercise_reps,
                                                min_reps: vm.form.min_reps,
                                                max_reps: vm.form.max_reps,
                                                rep_unit: vm.form.rep_unit,
                                                load_number: vm.form.load_number,
                                                tempo: vm.form.tempo,
                                                rest_minutes: vm.form.rest_minutes ? vm.form.rest_minutes : 0,
                                                rest_seconds: vm.form.rest_seconds ? vm.form.rest_seconds : 0,
                                                is_disable: 0,
                                            };

                            for (let i = 0; i < Math.abs(setCount); i++) {
                                vm.setSettings.push(setting);
                            }

                            vm.setSettings = JSON.parse(JSON.stringify(vm.setSettings));
                        } else if (setCount < 0) {
                            let settings = JSON.parse(JSON.stringify(vm.setSettings));

                            settings.splice(0, vm.form.sets);
                            settings.forEach(setting => setting.is_disable = 1);

                            vm.setSettings.length = vm.form.sets;
                            vm.setSettings.push(...settings);
                        }
                    }

                    if (isSubmit) {
                        vm.handleSubmitForm();
                    }
                }
            },

            handleRemoveSetSetting (index) {
                const vm      = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this exercise set`, 'Delete', false);

                Swal.fire(options).then(({isConfirmed}) => {
                    if (isConfirmed) {
                        vm.setSettings.splice(index, 1);
                        vm.handleSubmitForm();
                    }
                });
            },

            handleDeleteWorkoutAttribute (attribute) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this attribute`, 'Delete');

                options.preConfirm = function () {
                                        const params = { id: attribute.id, workout_attribute_type_id: attribute.workout_attribute_type_id }

                                        return vm.deleteWorkoutAttribute(params).then((result) => {
                                            if (result) {
                                                vm.getWorkoutAttributeTypes();
                                            }
                                        });
                                    };

                Swal.fire(options);
            },

            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url);
            },

            handleToggleExerciseSet (event) {
                const vm = this;

                if (vm.form.is_variable) {
                    let setSettings = [];

                    if (vm.setSettings && vm.setSettings.length) {
                        vm.setSettings.forEach(setting => {
                            setting.reps = vm.form.exercise_reps,
                            setting.rep_unit = vm.form.rep_unit,
                            setting.load_number = vm.form.load_number,
                            setting.tempo = vm.form.tempo,
                            setting.rest_minutes = vm.form.rest_minutes ? vm.form.rest_minutes : 0,
                            setting.rest_seconds = vm.form.rest_seconds ? vm.form.rest_seconds : 0,

                            setSettings.push(setting)
                        });

                        vm.setSettings = JSON.parse(JSON.stringify(setSettings));

                        vm.handleSubmitForm();
                    } else {
                        vm.handleExerciseSet();
                    }
                } else {
                    vm.handleSubmitForm();
                }
            },

            handleFailure () {
                const vm = this;

                if (vm.setSettings && vm.setSettings.length) {
                    vm.setSettings.forEach(setting => setting.rep_unit = vm.form.is_failure ? 'Max Reps' : 'Reps');

                    vm.setSettings = JSON.parse(JSON.stringify(vm.setSettings));
                }

                vm.handleSubmitForm();
            },

            autoGrowTextarea (event) {
                const element = event.target;

                element.style.height = "5px";
                element.style.height = (element.scrollHeight) + "px";
            },

            filterLoadNumber (exercise) {
                if (exercise.load_unit == 'Light' || exercise.load_unit == 'Medium' || exercise.load_unit == 'Heavy' || exercise.load_unit == 'Easy' || exercise.load_unit == 'Moderate' || exercise.load_unit == 'Hard') {
                    return '';
                } else if (exercise.load_number == 0) {
                    return '--';
                }

                return exercise.load_number;
            },
        }
    }
</script>

<style scoped>
    .multiselect.small_box {
        width: 80px;
        min-height: 25px;
        margin: 0 7px;
        font-size: 12px;
    }

    .time_selection .form_grp {
        padding: 10px;
        width: 50%;
        margin: 0;
        align-items: flex-end;
    }

    .grp_wpr {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        padding: 20px 0 5px 0;
    }

    .grp_wpr label .box_item{
        font-size: 13px;
        line-height: 15px;
        border-radius: 4px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.3s ease-in-out;
    }
    .grp_wpr label .box_item i{
        font-size: 15px;
    }
    .grp_wpr label.yes .box_item {
        border: 1px solid #b2eabf;
        background: #effff2;
        color: #23993e;
    }
    .grp_wpr label.no .box_item {
        border: 1px solid #ffb4b4;
        background: #ffecec;
        color: #eb1414;
    }
    .grp_wpr label input[type="radio"]:checked ~ .box_item {
        opacity: 1;
    }

    .question-options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 25px 0 5px;
        gap: 20px;
    }
    .question-options label {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
    }
    .question-options label p{
        font-size: 13px;
        line-height: 18px;
        padding-left: 12px;
    }
    .question-options .radio_box{
        width: 13px;
        height: 13px;
        border: 1px solid #5a5a5a;
        border-radius: 50%;
        position: relative;
        display: block;
        margin-top: 3px;
    }
    .question-options .radio_box .dot{
        position: absolute;
        background: #5a5a5a;
        border-radius: 50%;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .question-options label input[type="radio"]:checked ~ .radio_box .dot{
        transform: scale(1);
    }


    .modal.add_question .modal_header {
        padding: 0 45px;
    }

    .modal.add_question .modal_body {
        padding: 30px 45px 35px 45px;
        display: block;
    }

    .mcq_list li {
        border: 1px solid #d9d9d9;
        padding: 0 15px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .mcq_list li input {
        padding: 12px 15px 10px 0;
        font-size: 14px;
        line-height: 19px;
    }

    .mcq_list li button {
        border-left: 1px solid #d9d9d9;
        padding: 0 0 0 15px;
    }

    .col-2 {
        display: flex;
        margin: 0 -15px;
    }

    .col-2 .color_palette {
        flex: 1 0 50%;
        padding: 10px 15px;
    }

    .preview_content {
        width: 40%;
        position: relative;
        margin-right: -40%;
        transition: all 0.3s ease-in-out;
        background: #fff;
        z-index: 2;
    }

    .preview_content.show {
        margin-right: 0;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 2px;
        font-size: 14px;
    }

    .tab_sec li {
        display: flex;
        align-items: center;
    }

    .tab_sec .tag {
        padding: 2px 5px;
        border-radius: 7px;
        font-size: 8px;
        line-height: 10px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 5px;
    }

    .cover_type {
        margin: 15px -35px 15px -35px;
        background: #fbfbfb;
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        padding: 20px 35px;
    }

    .cover_type .type_list {
        justify-content: space-around;
        margin-top: 15px;
    }

    .days{
        display: flex;
        padding: 15px 0;
        gap: 7px;
    }
    .cell .days{
        border-top: 1px solid #E6E6E6;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0 20px;
    }
    .days li{
        padding: 4px 0;
        flex: 0 1 55px;
    }
    .days li label{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin: 0;
        padding: 10px 6px 12px 6px;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
    }
    .days li label span{
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .days li label .tick_box{
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #5a5a5a;
        position: relative;
        margin-top: 10px;
    }
    .days li label .tick_box:after{
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        background-color: #5a5a5a;
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .days li label input{
        margin-right: 7px;
        opacity: 0;
    }
    .days li label.active{
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid #b0d1ff;
    }
    .days li label.active .tick_box:after{
        transform: scale(1);
    }

    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 30px auto;
    }

    .cell:after,
    .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .cell .msg_preview {
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
    }

    .cell .sms_preview {
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
    }

    .cell .msg_preview:after,
    .cell .sms_preview::after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .cell .sms_preview:after {
        right: 30px;
        left: auto;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview p,
    .cell .sms_preview p {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview p span {
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        font-weight: 500;
        color: #121525;
    }

    .hourly_info {
        padding: 15px;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        background: #fff;
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #757575;
        text-align: center;
    }

    .form_grp :deep(.multiselect-single-label span) {
        font-size: 13px;
    }

    :deep(.cell .dashboard_footer) {
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }

    :deep(.cell .dashboard_footer ul li) {
        padding: 2px;
    }

    :deep(.cell .dashboard_footer ul li a) {
        width: 20px;
        height: 20px;
        border: 1px solid #2f7eed;
        font-size: 10px;
        color: #2f7eed;
        text-decoration: none;
    }

    :deep(.cell .dashboard_footer h4) {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }

    :deep(.cell .dashboard_footer p) {
        font-size: 9px;
        line-height: 11px;
    }

    .info_bar {
        display: flex;
        flex-direction: column;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 10px;
        margin: 20px;
    }
    .time {
        min-width: 150px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 10px 25px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .time span {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        display: block;
    }
    .step_bar2 {
        position: relative;
        padding: 20px 30px 10px 30px;
    }
    .step_bar2 ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 25px;
        position: relative;
        z-index: 1;
    }

    .step_bar2 ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .step_bar2 ul li h6 {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        white-space: nowrap;
        text-align: center;
    }

    .step_bar2 ul li:first-child h6 {
        left: 0;
        transform: none;
        text-align: left;
    }

    .step_bar2 ul li:last-child h6 {
        right: 0;
        left: auto;
        transform: none;
        text-align: right;
    }

    .step_bar2 ul li span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid #e5e5e5;
        font-size: 12px;
        font-weight: 500;
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .step_bar2 .total_bar {
        position: absolute;
        content: '';
        top: 32px;
        left: 30px;
        right: 30px;
        height: 2px;
        background: #d9d9d9;
    }

    .step_bar2 .total_bar span {
        background: #a1c8ff;
        position: absolute;
        left: 0;
        height: 100%;
        transition: all 0.3s ease-in-out;
    }

    .step_bar2 ul li.active span {
        background: #2f7eed;
        color: #fff;
        border-color: #a1c8ff;
    }

    .reset-default {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        line-height: 15px;
        color: #747474;
        font-weight: 400;
        cursor: pointer;
        position: relative;
    }

    .cover-header-with-text {
        display: flex;
    }

    .cover-header-with-text .small-cover-info {
        margin: 0;
        margin-left: 10px;
    }

    .cover-header-with-text div > small {
        font-size: 12px;
    }

    .flex-center{
        display: flex;
        align-items:center;
    }


    /* toggle_section */
    .toggle_section{
        width: 25px;
        height: 25px;
        font-size: 16px;
        color: #999;
        cursor: pointer;
        margin-left: 15px;
    }
    .toggle_section i{
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }

    .global_setting .tabs_content .section_content{
        position: relative;
    }
    .global_setting .tabs_content .section_content .section_header{
        margin: 10px 0;
    }
    .global_setting .tabs_content .section_content:after{
        content: '';
        position: absolute;
        left: 20px;
        top: 100%;
        right:20px;
        border-bottom: 1px solid #e9e9e9;
    }
    .global_setting .tabs_content .section_content.show:after, .global_setting .tabs_content .section_content:last-of-type:after, .global_setting .tabs_content .section_content.open_area:after{
        border: 0;
    }
    .global_setting .tabs_content .section_content .toggle_content{
        max-height: 0;
        overflow: hidden;
        animation: smoothSlidedown 0.3s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .global_setting .tabs_content .section_content.show .toggle_content{
        max-height: 1000vh;
        animation: smoothSlideup 0.8s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .global_setting .tabs_content .section_content.show .toggle_section i{
        transform: rotate(-180deg);
    }

    /* --- */

    .section_content .section_header .status{
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        margin: 0 0 0 auto;
        flex-grow: 0;
    }
    .section_content .section_header .switch_option{
        flex: 1 1 auto;
    }
    .section_header h2 span{
        font-size: 13px;
        line-height: 17px;
        color: #f2a31d;
        display: block;
        padding-top: 7px;
    }
    .section_header h2 a{
        font-size: 13px;
        line-height: 17px;
        color: #121525;
        display: block;
        padding: 7px 7px 0 7px;
        position: relative;
    }
    .section_header h2 a .quick_info{
        border-radius: 5px;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 15px rgb(0 0 0 / 10%);
        background: #fff;
        max-width: 200px;
        min-width: 100px;
        text-align: center;
        position: absolute;
        left: 0;
        top: auto;
        bottom: 100%;
        display: none;
        margin-bottom: 10px;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 7px 10px;
    }
    .section_header h2 a .quick_info:after {
        content: "";
        position: absolute;
        bottom: -6px;
        top: auto;
        left: 10px;
        width: 10px;
        height: 10px;
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 0;
        background: #fff;
        transform: rotate(45deg);
        z-index: 2;
    }
    .section_header h2 a:hover .quick_info {
        display: block;
        animation: smoothMove 0.5s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    .btn_list{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 10px;
    }
    .btn_list li{
        font-size:13px;
        line-height: 16px;
        color: #121525;
        padding: 5px 10px;
        cursor: pointer;
    }

    @media(max-width: 1499px) {
        .time_selection .form_grp {
            flex: 0 0 100%;
            width: 100%;
        }

        .selection_box {
            font-size: 11px;
            line-height: 15px;
        }

        .time_listing li {
            padding: 3px;
        }

        .tab_sec li {
            font-size: 12px;
            line-height: 15px;
            padding: 8px 12px;
        }
    }

    :deep(.weekdays .multiselect-single-label-text) {
        text-transform: capitalize;
    }

    :deep(.question_list) {
        display: block;
        margin: 0;
    }

    :deep(.question_list li) {
        width: 100%;
    }

    :deep(.question_list li h5 label) {
        margin-right: auto;
        margin-left: 0;
        padding-left: 0;
    }

    .grp_wpr.grp_option {
        display: flex;
        flex-wrap: wrap;
    }

    .box_item {
        flex: 1 0 calc(50% - 10px);
    }

    .box_item label {
        border: 1px solid #CBCDD3;
        border-radius: 6px;
        padding: 5px 7px;
        display: flex;
        align-items: center;
    }

    .mrl-5 {
        margin: 0 -5px;
    }

    .p-10-15 {
        padding: 10px 15px !important;
    }
    :deep(.dp__input_wrap input){
        font-size: 12px;
        color: #5a5a5a;
    }
    .tab_slider{
        margin: 0 -15px;
    }
    :deep(.dp__main .dp__input){
        font-size: 11px;
        max-width: 120px;
        padding: 0 0 0 35px !important;
    }

    .addblock_section{
        position: relative;
        width: 100%;
        padding: 0 10px 20px 10px;
    }
    .addblock_section .add_types {
        max-width: 572px;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0,0,0,.1);
        text-align: center;
        z-index: 2;
    }
    .addblock_section .add_types:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        bottom: 100%;
        transform: scale(-1);
    }
    .addblock_section .add_types ul{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        padding: 30px 20px;
    }
    .addblock_section .add_types ul li{
        flex: 0 1 80px;
    }
    .addblock_section .add_types ul li .card_item{
        padding: 15px 5px;
    }
    .addblock_section .add_types .title{
        padding: 15px 30px;
        text-align: center;
        border-bottom: 1px solid #f5f5f5;
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        color: #121525;
    }
    .block_list .block_actions{
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .block_list .block_actions button{
        font-size: 15px;
        color: #121525;
        cursor: pointer;
    }
    .block_list .block_actions button.up_down{
        color: #999;
    }
    .block_list .block_actions .block_setting{
        position: relative;
        cursor: pointer;
    }
    .block_list .block_actions .block_setting i{
        font-size: 15px;
        color: #999;
    }
    .block_list .block_actions .block_setting .dropdown_wpr{
        min-width: 250px;
        max-height: 350px;
        overflow-y: scroll;
        right: 1px;
        left: auto;
        padding: 15px;
        margin-bottom: 10px;
        z-index: 4;
    }
    .block_list .block_actions .block_setting .dropdown_wpr::-webkit-scrollbar{
        display: none;
    }
    .block_list .block_actions .block_setting .dropdown_wpr:before{
        display: none;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .sec_label{
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        padding: 3px 0;
        display: block;
    }
    .block_list .block_actions .block_setting .dropdown_wpr ul li{
        padding-top: 13px;
        border: 0;
        background: #f5f5f5;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        margin-bottom: 10px;
        border-radius: 5px;
    }
    .block_list .block_actions .block_setting .dropdown_wpr ul li .switch_option h5{
        font-weight: 400;
    }
    .block_list .block_actions .block_setting .dropdown_wpr .group_item .input_label{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr, .block_list .block_actions .block_setting .dropdown_wpr .form_grp .multiselect{
        background: #fff;
    }
    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr input, .block_list .block_actions .block_setting .dropdown_wpr .form_grp .multiselect{
        height: 30px;
        min-height: 30px;
        font-size: 11px;
    }
    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr.has_suffix{
        padding-right: 40px;
    }
    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr.has_suffix .suffix{
        width: 40px;
        font-size: 11px;
        line-height: 14px;
        background: #f5f5f5;
        border-radius: 0 5px 5px 0;
    }
    .block_list .block_actions .block_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown){
        min-width: 100%;
    }
    .block_list .block_actions .block_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown ul.multiselect-options li){
        padding: 5px 10px;
        font-size: 11px;
        line-height: 15px;
    }
    .block_list li .block_panel{
        border: 1px solid #eaeaea;
        background: #f5f5f5;
        border-radius: 6px;
        padding: 30px;
        margin-bottom: 40px;
        transition: all 0.3s ease-in-out;
    }
    .block_list li .block_panel.active{
        border: 1px solid #c8dfff;
        background: #fbfbfb;
    }
    .block_panel li.add_item button{
        width: 150px;
        border-radius: 6px;
        background: #fff;
        border: 1px solid #f5f5f5;
        padding: 20px 10px;
        min-height: 134px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
        font-size: 13px;
        line-height: 18px;
        gap: 10px;
        color: #999;
        cursor: pointer;
    }

    .block_library{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 0;
    }
    .block_library ul.list{
        display: flex;
        flex-wrap: wrap;
        margin: 20px -10px;
        width: 100%;
    }
    .block_library ul.list.shake{
        animation: headShake 0.3s linear 0s infinite alternate;
    }
    .block_library ul.list > li{
        padding: 20px 10px;
        width: 33.333%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        position: relative;
    }
    .video_wpr {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        margin: 0;
        border-radius: 5px;
        overflow: hidden;
    }
    .video_wpr iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }
    .block_library ul li h6{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight:500;
    }
    .block_library ul li .action_items{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        padding: 0 10px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .block_library ul li:hover .action_items, .block_library ul li.selected .action_items, .block_library ul.target li .action_items{
        opacity: 1;
    }
    .block_library ul li .action_items label.checkbox span {
        width: 12px;
        flex: 0 0 12px;
        height: 12px;
        border-radius: 2px;
    }
    .block_library ul li .action_items label.checkbox span i{
        font-size: 8px;
    }
    .block_library ul li .action_items a{
        cursor: pointer;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
    }
    .block_library ul li .action_items a i{
        font-size: 9px;
        padding-right: 4px;
    }
    .block_library .library_header{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    .block_library .library_header a{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #5a5a5a;
        cursor: pointer;
    }
    .block_library .filter_search {
        /* border: 1px solid #efefef; */
        height: 34px;
        border-radius: 20px;
        padding: 0 34px 0 0;
        position: relative;
        background: #fbfbfb;
        max-width: 400px;
        width: 100%;
    }
    .block_library .filter_search input{
        font-size: 11px;
        line-height: 34px;
        color: #666;
        border: 0;
        width: 100%;
        padding: 0 15px;
        background: transparent;
        box-sizing: border-box;
    }
    .block_library .filter_search .search_btn {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 0;
        font-size: 11px;
        color: #333;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }
    .block_library .filters{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin: 25px 0 10px 0;
        position: relative;
        z-index: 2;
    }
    .block_library .filters h5{
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 400;
    }
    .filters .filter_item, .attribute_list{
        position: relative;
        font-size: 11px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        cursor: pointer;
    }
    .attribute_list{
        font-size: 12px;
        white-space: nowrap;
    }
    .filters .filter_item li, .other_filter_items li{
        font-size: 11px;
        line-height: 15px;
    }
    .other_filter_items{
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f1f1f1;
        border-radius: 50%;
        font-size: 10px;
        position: relative;
        cursor: pointer;
    }

    .attribute_bar{
        padding: 15px 0;
        background: #fff;
        border-left: 1px solid #e9e9e9;
        width: 230px;
        max-height: 100vh;
        overflow-y: overlay;
        position: relative;
        margin-right: -230px;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction:column;
    }
    .attribute_bar.show{
        margin-right: 0;
    }
    .attribute_bar::-webkit-scrollbar{
        width: 4px;
    }
    .attribute_bar::-webkit-scrollbar-thumb{
        border-radius: 2px;
        background: #cacaca;
    }
    .attribute_bar h3{
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        padding: 0 20px 5px 20px;
        display: flex;
        align-items: center;
    }
    .attribute_bar h3 button{
        font-size: 13px;
        color: #999;
        margin-left: auto;
        cursor: pointer;
    }
    .toggle_btn{
        display: flex;
        justify-content: flex-end;
        padding: 6px 20px 12px 20px;
    }
    .toggle_btn a{
        padding: 4px 10px;
        background: #f5f5f5;
        font-size: 10px;
        line-height: 12px;
        color: #121525;
        font-weight: 500;
        display: block;
        border-radius: 10px;
        cursor: pointer;
    }
    .attribute_grp{
        border-top: 1px solid #f5f5f5;
    }
    .attribute_grp h4{
        padding: 12px 15px;
        font-size: 13px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .attribute_grp h4 a{
        color: #5a5a5a;
        margin-right: 15px;
        font-size: 11px;
        display: flex;
        align-items: center;
    }
    .attribute_grp h4 a.pinned{
        color: #2f7eed;
    }
    .attribute_grp h4 > i{
        margin-left: auto;
        font-size: 11px;
        color: #999;
        transition: all 0.3s ease-in-out;
    }
    .attribute_grp ul{
        padding: 0 15px;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }
    .attribute_grp.active ul{
        max-height: 10000px;
    }
    .attribute_grp.active h4 > i{
        transform: rotate(-180deg);
    }
    .attribute_bar ul li{
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 10px 15px;
    }
    .attribute_bar ul li:last-child{
        margin-bottom: 15px;
    }
    .attribute_bar ul li label.checkbox, .dropdown_wpr ul li label.checkbox{
        justify-content: flex-start;
        gap: 10px;
        cursor: pointer;
    }
    .add_attribute{
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        padding: 10px 20px;
        background: #2f7eed;
        color: #fff;
        border-radius: 5px;
        margin: auto 20px 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        gap:7px;
        cursor: pointer;
    }
    .add_attribute i{
        font-size: 9px;
    }
    .filters .attribute_bar{
        position: fixed;
        right: -230px;
        top: 0;
        height: 100vh;
        overflow-y: scroll;
        transition: all 0.3s ease-in-out;
    }
    .filters .attribute_bar.show{
        right: 0;
    }
    .search_tags{
        background: #fbfbfb;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;
        margin-top: 20px;
    }
    .search_tags li{
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 4px 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        position: relative;
    }
    .search_tags .delete_btn{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        background: #eb1414;
        color: #fff;
        position: absolute;
        right: -5px;
        top: -5px;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .search_tags li:hover .delete_btn{
        opacity: 1;
    }
    .dropdown_wpr ul li label.checkbox span i{
        margin: 0;
    }
    .attribute_bar ul li label.checkbox span.active i, .dropdown_wpr ul li label.checkbox span.active i{
        transform: scale(1);
        opacity: 1;
    }
    .bulk_action{
        border-right: 1px solid #d9d9d9;
        padding-right: 20px;
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .bulk_action .delete_btn{
        color: #eb1414;
        cursor: pointer;
    }
    .filters .bulk_action .filter_item, .filters .bulk_action .filter_item i{
        color: #2f7eed;
    }
    .filter_item i{
        font-size: 9px;
        padding: 5px;
        vertical-align: top;
    }
    .filters .filter_item i, .attribute_list i {
        color: #7a7a7a;
        pointer-events: none;
    }
    .filters .filter_item .dropdown_wpr, .attribute_list .dropdown_wpr{
        min-width: 160px;
        overflow-y: hidden;
    }
    .filters .filter_item .dropdown_wpr ul, .attribute_list .dropdown_wpr ul{
        max-height: 400px;
        overflow-y: scroll;
    }
    .attribute_list .dropdown_wpr ul{
        max-height: 200px;
        overflow-y: scroll;
    }
    .filters .filter_item .dropdown_wpr ul::-webkit-scrollbar, .other_filter_items .dropdown_wpr::-webkit-scrollbar, .attribute_list .dropdown_wpr ul::-webkit-scrollbar, .attribute_list .dropdown_wpr ul::-webkit-scrollbar{
        display: none;
    }
    .filters .filter_item .dropdown_wpr ul li, .other_filter_items .dropdown_wpr ul li, .attribute_list .dropdown_wpr ul li{
        border: 0;
    }
    .filters .filter_item .dropdown_wpr ul li.active{
        background: #f5f5f5;
    }
    .dropdown_wpr ul li.dropdown-header{
        background: #e9e9e9;
    }

    .block_list li .block_panel ul{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }
    .block_list li .block_panel ul li{
        padding: 10px 15px;
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        position: relative;
    }
    .block_list li .block_panel ul li .block_action{
        position: absolute;
        right: 15px;
        bottom: 10px;
        display: flex;
        align-items: center;
        gap: 8px;
        transition: all 0.3s ease-in-out;
        opacity: 0;
    }
    .block_list li.circuit .block_panel ul li .block_action{
        right: 30px;
    }
    .block_list li .block_panel ul li .block_action button{
        font-size: 11px;
        color: #eb1414;
        cursor: pointer;
    }
    .block_list li .block_panel ul li:hover .block_action{
        opacity: 1;
    }
    .block_list li .block_panel ul li h6{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight:500;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .block_list li .block_panel ul li h6.short_info{
        flex-wrap: nowrap;
    }
    .block_list li .block_panel ul li h6 .v_tag{
        width: 14px;
        height: 14px;
        background: #999;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        line-height: 10px;
        font-weight: 500;
        color: #fff;
        margin-right: 5px;
        border-radius: 50%;
    }
    .block_list li .block_panel ul li.rest_list{
        width: auto;
    }
    .block_list li.circuit .block_panel ul li{
        padding-right: 30px;
    }
    .block_list li .block_panel ul li .circuit{
        width: 40px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        position: absolute;
        font-size: 15px;
        color: #2f7eed;
        top: 50%;
        right: -12px;
        transform: translateY(-50%);
        z-index: 1;
        gap: 5px;
        visibility: hidden;
    }
    .block_list li .block_panel ul li .circuit a{
        font-size: 10px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        width: 40px;
        text-align:center;
    }
    .block_list li.circuit .block_panel ul li .circuit{
        visibility: visible;
    }
    /* .block_list li:not(.circuit) .block_panel ul li:nth-child(3):before{
        width: 22px;
        height: 22px;
        background: #2f7eed;
        border-radius: 11px;
        content: "SS";
        position: absolute;
        font-size: 10px;
        font-weight: 500;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.5px;
        top: 50%;
        left: -11px;
        transform: translateY(-50%);
        z-index: 1;
    } */

    .rest_list{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .rest_circle{
        width: 110px;
        height: 110px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #ffc4c4;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        cursor: pointer;
    }
    .rest_circle span{
        font-size: 15px;
        line-height: 20px;
    }
    .edit_rest{
        width: 230px;
        position: absolute;
        top: 100%;
        right: 1px;
        left: auto;
        margin-top: 10px;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0,0,0,.1);
        text-align: center;
        z-index: 4;
    }
    /* .edit_rest:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        right: calc(100% - 4px);
        bottom: 50%;
        transform: rotate(90deg);
    } */
    .edit_rest .title{
        padding: 10px 20px;
        text-align: center;
        border-bottom: 1px solid #f5f5f5;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
    }
    .edit_rest .setting_wpr{
        padding: 20px;
    }
    .edit_rest .input_label{
        font-size: 11px;
        line-height: 13px;
    }
    .edit_rest .field_wpr input{
        height: 32px;
        font-size: 11px;
    }
    .edit_rest :deep(.color-picker .field_wpr input){
        height: 30px;
        padding: 0 10px;
        font-size: 11px;
    }
    .edit_rest :deep(.color-picker .field_wpr.has_prefix){
        padding-left: 30px;
    }
    .edit_rest :deep(.color-picker .field_wpr.has_prefix .prefix-right){
        top: 3px;
        bottom: 3px;
        right: 3px;
    }
    .edit_rest :deep(.color-picker .field_wpr.has_prefix .prefix){
        width: 30px;
        height: 30px;
    }
    .edit_rest :deep(.color-picker .pickr), .edit_rest :deep(.color-picker .pickr .pcr-button){
        width: 20px;
        height: 20px;
        line-height: 0;
    }
    .edit_rest :deep(.color-picker .sub_header){
        font-size: 11px;
        line-height: 14px;
    }
    .edit_rest :deep(.color-picker .swatches li){
        padding: 4px;
    }
    .edit_rest :deep(.color-picker .swatches li span){
        width: 20px;
        height: 20px;
    }
    .edit_rest :deep(.color-picker .swatches li span i){
        font-size: 10px;
    }
    .edit_rest .action_wpr{
        padding: 12px 20px;
        border-top: 1px solid #f5f5f5;
        margin: 0;
    }
    .edit_rest .action_wpr .btn {
        min-width: auto;
        height: 30px;
        font-size: 10px;
        line-height: 28px;
        padding: 0 15px;
    }
    .edit_rest .action_wpr .btn.save_btn{
        color: #fff;
    }
    .workout_config .modal_container{
        padding: 0;
        max-width: 700px;
        width: 100%;
    }
    .workout_config .modal_header{
        padding: 20px 30px;
        width: 100%;
        border-bottom: 1px solid #e9e9e9;
    }
    .workout_config .modal_footer{
        padding: 20px 30px;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        border-radius: 0 0 10px 10px;
    }
    .workout_config .modal_header h2 {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        text-align: left;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .workout_config .modal_header button{
        color: #999;
        font-size: 15px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-self: center;
    }
    .workout_config .modal_body{
        width: 100%;
        padding: 20px 30px;
        max-height: 600px;
    }
    .workout_config .edit_content{
        display: flex;
        gap: 30px;
        margin-bottom: 20px;
    }
    .workout_config .edit_content .thumb_section{
        flex: 0 1 600px;
        display: flex;
        background: #fbfbfb;
        border: 1px solid #f5f5f5;
        border-radius: 5px;
    }
    .workout_config .edit_content .thumb_section .thumb_info{
        padding: 0 15px 15px 15px;
        border-left: 1px solid #f5f5f5;
        flex: 1 1 auto;
    }
    .workout_config .edit_content .thumb_section .thumb_title{
        font-size: 15px;
        line-height: 23px;
        font-weight: 500;
        color: #121525;
        margin: 5px 0 15px 0;
        display: flex;
    }
    .workout_config .edit_content .thumb_section .thumb_title .v_tag{
        width: 20px;
        height: 20px;
        background: #999;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        line-height: 14px;
        font-weight: 600;
        color: #fff;
        margin-left: 10px;
        border-radius: 50%;
    }
    .workout_config .edit_content .thumb_section .exercise_info{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        margin: 10px 0;
    }
    .workout_config .edit_content .video_section{
        flex: 0 1 240px;
    }
    .workout_config .edit_content .video_section_flip{
        flex: 0 1 240px;
    }
    .workout_config .edit_content .video_section img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .workout_config .edit_content .video_section_flip img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: scale(-1, 1);
    }
    .workout_config .edit_content .setting_wpr{
        flex: 1 0 180px;
    }
    .set_wpr{
        display: flex;
        flex-wrap: nowrap;
        align-items: initial;
        margin: 0 -3px;
    }

    .set_wpr .single_item{
        flex: 1 1 25%;
        padding: 10px 15px;
        margin: 3px;
        background: #f9f9f9;
        border-radius: 8px;
        height: auto;
    }
    .set_wpr .single_item.sets{
        flex: 1 1 20%;
    }
    .set_wpr .single_item.load{
        flex: 1 1 30%;
    }
    .set_wpr .public_name{
        flex: 0 1 294px;
        padding: 0 7px;
    }
    .set_wpr .public_descriptions{
        max-width: 544px;
        width: 100%;
        padding: 0 7px;
    }
    .set_wpr .item_wpr{
        flex: 0 1 25%;
        padding: 7px;
    }
    .set_wpr .item_wpr:last-of-type{
        margin-left: auto;
    }
    .column-2{
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
    }
    .column-2 .item{
        flex: 1 0 calc(50% - 7px);
    }
    .set_wpr .input_label{
        font-size: 14px;
        line-height: 19px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }
    .workout_config .fld_label{
        font-size: 11px;
        line-height: 14px;
        margin-bottom: 5px;
        display: block;
    }
    .workout_config .field_wpr input, .workout_config .form_grp .multiselect{
        height: 40px;
        min-height: 40px;
        font-size: 13px;
    }
    .form_grp.set_wpr .multiselect {
        min-height: 40px;
    }
    .var_list{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        column-gap: 30px;
        row-gap: 5px;
        padding: 10px 0;
    }
    .var_list .blank_space{
        min-width: 21%;
    }
    .radio_opt {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
    }
    .radio_opt p{
        font-size: 14px;
        line-height: 19px;
        padding-left: 8px;
    }
    .radio_box{
        width: 13px;
        height: 13px;
        border: 1px solid #5a5a5a;
        border-radius: 50%;
        position: relative;
        display: block;
        margin-top: 4px;
    }
    .radio_box .dot{
        position: absolute;
        background: #5a5a5a;
        border-radius: 50%;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .radio_opt input[type="radio"]:checked ~ .radio_box .dot{
        transform: scale(1);
    }
    .var_table{
        padding: 20px 0;
    }
    .var_table table{
        width:100%;
        background: #fbfbfb;
        border-radius: 6px;
        border-collapse: collapse;
    }
    .var_table table thead{
        border-bottom: 1px solid #efefef;
    }
    .var_table table tbody tr:not(:last-child){
        border-bottom: 1px solid #f3f3f3;
    }
    .var_table table th{
        padding: 12px 18px;
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 400;
    }
    .var_table table td{
        padding: 10px 18px 15px 18px;
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 300;
        vertical-align: bottom;
    }
    .var_table table td .row{
        display: flex;
        align-items: flex-end;
        gap: 10px;
    }
    .var_table table td input{
        width: 50px;
        font-weight: 300;
        height: 30px;
        border: 1px solid #CBCDD3;
        border-radius: 5px;
        padding-left: 5px;
    }
    .other_content{
        width: 100%;
        padding: 20px 0 0 0;
    }
    .group_title{
        display: flex;
        align-items: flex-start;
        gap: 15px;
    }
    .group_title h4{
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        color: #121525;
        margin-bottom: 10px;
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 5px;
        align-items: center;
    }
    .group_title h4 span{
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
    }
    .att_tags{
        background: #fbfbfb;
        border-radius: 6px;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
    }
    .att_tags li{
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 7px 15px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        position: relative;
    }
    .att_tags .delete_btn{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        background: #eb1414;
        color: #fff;
        position: absolute;
        right: -5px;
        top: -5px;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .att_tags li:hover .delete_btn{
        opacity: 1;
    }
    .att_tags li.exercise, .search_tags li.exercise{
        border-color: #88d3d9;
    }
    .att_tags li.position, .search_tags li.position{
        border-color: #b99191;
    }
    .att_tags li.contraction, .search_tags li.contraction{
        border-color: #968bca;
    }
    .att_tags li.muscle, .search_tags li.muscle{
        border-color: #76afff;
    }
    .att_tags li.movement, .search_tags li.movement{
        border-color: #cb72ff;
    }
    .att_tags li.equipment, .search_tags li.equipment{
        border-color: #56e094;
    }
    .att_tags li.skeletal, .search_tags li.skeletal{
        border-color: #fb76c8;
    }
    .att_tags li.difficulty, .search_tags li.difficulty{
        border-color: #f2a31d;
    }
    .empty_box{
        background: #fbfbfb;
        padding: 40px 0;
    }
    .empty_box img {
        max-height: 60px;
        margin-bottom: 15px;
    }
    .other_content .field_wpr{
        background: #fff;
    }
    .other_content textarea{
        font-size: 13px;
        line-height: 18px;
        resize: vertical;
    }
    .video-overlay {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        top: 0;
        cursor: pointer;
    }
    .section_container {
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
        padding: 30px 50px;
    }
    .section_title{
        font-size: 22px;
        line-height: 30px;
        color: #121525;
        font-weight: 400;
        text-align: center;
        padding: 0 0 20px 0;
    }
    .content_panel{
        border-radius: 8px 8px 0 0;
        background: #fff;
        position: relative;
        padding: 30px;
    }
    .content_panel.circuit .set_wpr .single_item.sets,
    /* .content_panel.circuit .set_wpr .item_wpr.rest, */
    .content_panel.circuit .form_grp :deep(ul.multiselect-options li#multiselect-option-3),
    /* .content_panel.progression .form_grp :deep(ul.multiselect-options li[aria-label="Using Time"]), */
    .content_panel.no_superset .form_grp :deep(ul.multiselect-options li#multiselect-option-3),
    .disable{
        pointer-events: none;
        opacity: 0.7;
    }
    .superset{
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 15px;
        max-width: 140px;
        margin: 20px auto;
        padding: 7px 15px;
        font-size: 15px;
        line-height: 18px;
        font-weight: 400;
        color: #121525;
    }
    .superset i{
        color: #2f7eed;
        padding-right: 7px;
    }
    .global_setting .section_header h2{
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .global_setting .section_header h2 input{
        font-size: 16px;
        line-height: 20px;
        background: transparent;
        width: 200px;
        background: #f0f5ff;
        padding: 10px 15px;
        border-radius: 5px;
    }
    .global_setting .section_header h2 .save_btn{
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }
    .form_grp.small{
        margin: 0;
    }
    .form_grp.small .field_wpr{
        width: 80px;
    }
    .form_grp.small :deep(.multiselect-multiple-label), .form_grp.small :deep(.multiselect-placeholder), .form_grp.small :deep(.multiselect-single-label){
        padding: 0 20px 0 5px;
    }
    .form_grp.small :deep(.multiselect){
        min-height: 30px;
        height: 30px;
    }
    .form_grp.small :deep(.multiselect-single-label span){
        font-size: 11px;
    }
    .form_grp.small :deep(.multiselect-caret){
        margin-right: 5px;
    }

    .smart_blocklist .add_btn{
        font-size: 13px;
        line-height: 16px;
    }
    .smart_blocklist .add_btn i {
        margin-right: 5px;
        font-size: 9px !important;
    }
    .smart_blocklist > ul{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .smart_blocklist > ul .block_info{
        border: 1px solid #f5f5f5;
        background: #fff;
        border-radius: 6px;
        display: flex;
        flex-wrap: wrap;
        padding: 7px 15px 20px 15px;
    }
    .smart_blocklist > ul .block_info > li{
        padding: 7px;
        width: 33.333%;
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
    .smart_blocklist > ul .block_info li h6{
        font-size: 9px;
        line-height: 11px;
        color: #121525;
        font-weight: 400;
        margin: 5px 0;
    }
    .global_setting .tabs_content form>.action_wpr{
        padding: 20px 30px;
        position: sticky;
        bottom: 0;
        left: 0;
        flex-wrap: wrap;
        background: #fff;
        border-radius: 0 0 8px 8px;
        border-top: 1px solid #e9e9e9;

    }
    .switch_btn_wpr{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        margin-right: auto;
        gap: 15px;
    }

    .thumb_section .flip_image{
        margin-right: 5px;
    }
    @keyframes smoothMove {
        0% {
            bottom: 30%;
            opacity: 0;
        }

        10% {
            bottom: 30%;
            opacity: 0.5;
        }

        100% {
            bottom: 100%;
            opacity: 1;
        }
    }
    @media(max-width: 1199px){
        .info_bar{
            margin: 20px 10px;
        }
        .cover_type{
            margin: 15px -25px;
        }
    }
    @media(max-width: 991px){
        .prog_edit{
            padding: 20px;
        }
    }
    @media(max-width: 899px){
        .set_wpr{
            flex-wrap: wrap;
        }
        .set_wpr .single_item{
            flex: 0 1 50%;
        }
    }
    @media(max-width: 767px){
        .category_bar input, .category_bar .quest_title{
            font-size: 13px;
        }
        .workout_config .edit_content{
            flex-wrap: wrap;
        }
        .workout_config .edit_content .setting_wpr{
            width: 100%;
        }
        .workout_config .edit_content .setting_wpr .form_grp{
            max-width: 294px;
        }
        .content_panel.circuit .set_wpr{
            flex-wrap: wrap;
        }
        .content_panel.circuit .set_wpr .item_wpr.rest{
            flex: 0 1 294px;
            margin: 15px 0 0 0;
        }
        .content_panel.circuit .set_wpr .single_item{
            flex: 0 1 30%;
        }
        .content_panel.circuit .set_wpr .item_wpr{
            flex: 0 1 40%;
        }
        .section_container{
            padding: 30px 40px;
        }
    }
    @media(max-width: 599px){
        .workout_config .edit_content .thumb_section .thumb_title{
            font-size: 13px;
            line-height: 20px;
            margin: 10px 0;
        }
        .switch_btn_wpr{
            width: 100%;
        }
        .set_wpr .single_item{
            flex: 0 0 100%;
        }
    }
    @media(max-width: 499px){
        .day_type li .day_wpr h4{
            font-size: 11px;
        }
        .sqr_input{
            width: 20px;
            height: 20px;
            font-size: 10px;
        }
        :deep(.dp__main .dp__input){
            font-size: 10px;
            padding: 0 0 0 25px !important;
        }
        :deep(.dp__main .dp__input_icons){
            width: 12px;
        }
        .day_type li .day_wpr label{
            width: 40px;
            min-width: auto;
            flex-shrink: 0;
        }
        .time_listing li{
            width: 25%;
        }
        .circumference_list li{
            width: 100%;
        }
        .custom_list li{
            padding: 15px;
        }
        .custom_list li .section_wpr{
            padding: 10px 20px;
        }
        .category_bar .capsule_btn{
            padding: 0 10px 0 0;
        }
        .category_bar .add_btn{
            margin: 0 0 0 10px;
            padding-right: 10px;
        }
        .sub_categories{
            padding: 20px 15px;
        }
        .prog_edit{
            padding: 15px;
        }
        .cover_type{
            margin: 15px -15px;
        }
        .section_container{
            padding: 30px 15px;
        }
        .content_panel{
            padding: 20px;
        }
        .content_panel.circuit .set_wpr .single_item, .content_panel.circuit .set_wpr .item_wpr{
            padding: 7px 4px;
        }
        .var_list{
            column-gap: 20px;
        }
        .section_title{
            font-size: 18px;
            line-height: 25px
        }
        .workout_config .edit_content .thumb_section{
            flex-wrap: wrap;
        }
        .workout_config .edit_content .video_section, .workout_config .edit_content .thumb_section .thumb_info{
            flex: 0 0 100%;
        }
        .workout_config .edit_content .video_section_flip, .workout_config .edit_content .thumb_section .thumb_info{
            flex: 0 0 100%;
        }
        .workout_config .edit_content .thumb_section .thumb_info{
            border-left: 0;
            border-top: 1px solid #f5f5f5;
        }
        .global_setting .tabs_content form>.action_wpr{
            padding: 20px !important;
        }
        .switch_btn_wpr{
            margin-bottom: 20px;
        }
    }

    .attribute_bar ul li {
        display: flex;
        justify-content: space-between;
    }

    textarea.auto-grow {
        min-height: 70px;
        max-height: 200px;
        resize: none;
    }
    :deep(.multiselect-option.is-disabled) {
        color: var(--ms-option-color-pointed, #1f2937);
    }
</style>